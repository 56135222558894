<template>
  <div>
    <b-row align-h="center">
      <!-- card collapsible  -->
      <b-col md="8">
        <b-card title="Buat Laporan">
          <b-row>
            <b-col md="6">
              <b-form-group label="Tanggal Awal" label-for="start_date">
                <flat-pickr
                  id="start_date"
                  v-model="filter.start_date"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y' }"
                  placeholder="Tanggal Awal"
                />
                <!-- <p>Value: '{{ filter.start_date }}'</p> -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Tanggal Akhir" label-for="end_date">
                <flat-pickr
                  id="end_date"
                  v-model="filter.end_date"
                  class="form-control"
                  :config="{ dateFormat: 'd-m-Y' }"
                  placeholder="Tanggal Akhir"
                />
                <!-- <p>Value: '{{ filter.end_date }}'</p> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Jenis Laporan" label-for="type">
                <b-form-select
                  id="type"
                  v-model="filter.selectedType"
                  :options="filter.optionsType"
                />
                <!-- Selected: <strong>{{ filter.selectedStatus }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="mt-1 mb-2"
                block
                @click="getReport()"
              >
                Buat
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  VBModal,
  BForm,
  BFormFile,
  BModal,
  BButton,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BContainer,
  BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardActions,
    VueGoodTable,
    ToastificationContent,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormFile,
    BModal,
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    StatisticCardHorizontal,
    BContainer,
    BFormTextarea,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      filter: {
        start_date: "",
        end_date: "",
        selectedType: "",
        optionsType: [
          { value: 1, text: "Kontrak" },
          { value: 2, text: "Rekap Penggatian Part" },
          { value: 3, text: "Rekap Servis Unit" },
          { value: 4, text: "Rekap KM Mobil" },
        ],
      },
      id: null,
      meta: {
        current_page: 1,
        last_page: 0,
        from: 0,
        to: 0,
        per_page: 10,
        total: 0,
      },
      permissions: [],
      search: "",
      rows: [],
      searchTerm: "",
      errors: "",
      errMessage: "",
      file: null,
      statistic: null,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  methods: {
    moment(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getReport() {
      console.log(this.filter.selectedType)
      this.$http
        .get(
          "report?type=" + this.filter.selectedType+
            "&start_date=" +
            this.filter.start_date +
            "&" +
            "end_date=" +
            this.filter.end_date
        )
        .then((response) => {
          // console.log(response.data.data.url)
          const url = response.data.data.url;
          const link = document.createElement("a");
          link.href = url;
          const fileName = ".xlsx";
          link.setAttribute("download", fileName);
          link.click();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success export excel",
              variant: "success",
            },
          });
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    trigger($id) {
      this.id = $id;
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>